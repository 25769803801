<template>
  <div>
    <div class="top-btn">
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" >
            <el-form-item   >
              <el-select   clearable filterable placeholder="选择学院名称" style="width:150px">
                <el-option label="全部" value="" ></el-option>
                <el-option label="上架中" :value="1"  ></el-option>
                <el-option label="已下架" :value="0" ></el-option>
              </el-select>
            </el-form-item> 
          <el-form-item >
             <el-input placeholder="请输入专业名称"   ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" icon="el-icon-search"  >搜索</el-button>
          </el-form-item>
          <el-form-item class="fr">
            <el-button   size="small" icon="el-icon-plus" @click="dialogFormVisible = true" >新增</el-button>
            <el-button  size="small" @click="dialogFormVisible = true" >编辑</el-button>
            <el-button   size="small" >导入</el-button>
          </el-form-item>
      </el-form>
        
    </div>
    <div class="list params-wrap ">
      
      <paged-list v-model="queryParams" :data="pageData" :total="10"  >
        <template slot-scope="scope">
          <div class="box-style-c ">
            <div class="title">{{ scope.row.name }}-校区</div>
            <div class="content cfx ">
              <div class="depar"> <span>物理学院</span> </div>
              <div class="major"><span>专业名称</span></div>
              <div class="major-name">
                  <p>盎然发的晚饭饿饿一会好一他t6tgut77877元公告称也不会好一他t6tgut77877元公告称也不是大上测试</p>
              </div>
                
              
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle  @click="drawer = true"></el-button>
          <el-button type="danger" icon="el-icon-delete" circle > </el-button>
        </template>
      </paged-list>
    </div>
    <!-- 编辑 -->
    <el-dialog title="学院信息维护" center :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="100px">
        <el-form-item label="学校名称" >
          <el-input style="width:490px" placeholder="中国科学技术大学" clearable></el-input>
        </el-form-item>
        
        
        <div class="myTeam">
          <div class="myTeam-items" v-for="(team,index) in teamArr" :key="index"  >
              <el-form-item :label="'院系名称' + (index +1)"  >
                <el-input style="width:490px;" placeholder="院系名称" v-model="team.a" clearable></el-input>
                <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addTeam" style=" margin-left:10px; color:#28B892; font-size:20px;"></i>
                <i class="el-icon-remove-outline" v-if="index > 0" @click="removeTeam" style=" margin-left:10px; color:red; font-size:20px;"></i>
              </el-form-item>
              <!-- <div class="major-item" v-for="(major,index) in majorArr" :key="index"> -->
                <el-form-item :label="'专业名称' + (index +1)"  >
                  <el-input style="width:450px;"   v-model="team.b" placeholder="请输入专业名称" clearable></el-input>
                  <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addMajor" style=" margin-left:10px; color:#28B892; font-size:20px;"></i>
                  <i class="el-icon-remove-outline" v-if="index > 0" @click="removeMajor" style=" margin-left:10px; color:red; font-size:20px;"></i>
                </el-form-item>
              <!-- </div> -->
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-drawer
      title="院系详情"
      :visible.sync="drawer"
      size="36.5%"
      >
         <div class="drawer ">
            <div class="title">学院信息维护-校区</div>
            <div class="content cfx ">
              <div class="depar"> <span>物理学院</span> </div>
              <div class="major"><span>专业名称</span></div>
              <div class="major-name">
                  <p>盎然发的晚饭饿饿一会好一他t6tgut77877元公告称也不会好一他t6tgut77877元公告称也不是大上测试</p>
              </div>
            </div>
          </div>
    </el-drawer>
  </div>
</template>

<script>
import PagedList from "@/components/common/PagedList";
  export default {
      components: {
      PagedList
      },
      data() {
        return {
          drawer:false,
          dialogFormVisible:false,
          queryParams: {
            schoolName:''
          },
          form:{
            a:[],
            b:[]
          },
          pageData:[
            { name:'中国科技大学' },
             { name:'中国科技大学' }
          ],
          teamArr:[],
          team:{
            a:'',
            b:''
          },
          majorArr:[],
          
        }
      },
      methods:{
        opendrawer(){
          this.drawer = true
        },
         // 初始化地址、校区、专业
          initPage() {
            this.teamArr = [{ a: "", b: '',}];
            
          },
          // 添加校区，地址
          addTeam() {
            if (this.teamArr.filter((item) => !item.a || !item.b).length) {
              this.$message.warning("请完善校区信息再添加！");
              return;
            }
            this.teamArr.push({ team: "" });
          },
          // 删除校区 地址
          removeTeam(index) {
            this.teamArr = this.teamArr.filter((item, idx) => idx != index);
          },
          // 添加专业
          addMajor() {
            if (this.majorArr.filter((item) => !item.a ).length) {
              this.$message.warning("请完善专业信息再添加！");
              return;
            }
            this.majorArr.push({ major: "" });
          },
          // 删除专业
          removeMajor(index) {
            this.majorArr = this.majorArr.filter((item, idx) => idx != index);
          },
      },
      mounted() {
         this.initPage();
      },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.top-btn{margin: 10px 0px;}
/deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
.el-drawer__header{ margin-bottom: 0px;}
.drawer{ margin: 0px 20px;
  .title{font-size: 20px;}
    .content{
        .depar{ margin-top: 20px;
            span{ color: $c-white; font-size: 14px; background: $c-success; padding: 5px 10px;  border-radius: 5px; }
        }
        .major{ margin-top: 20px; 
            span{ color: $c-9; font-size: 14px; }
        }
        .major-name{ margin-top: 10px;
            p{font-size: 14px; color: $c-2; }
        }
    }
}   

</style>